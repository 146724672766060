import actions from './actions';
import initialState from './initialState';

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.LOGIN().type:
            return ({
                ...state,
                loggedIn: true,
                token: action.result[0].token,
                username: action.result[0].user
            });
        case actions.LOGOUT().type:
            return ({
                ...state,
                loggedIn: false,
                token: null,
                username: null
            });
        case actions.IFRAME_LOGIN().type: 
            return ({
                ...state,
                loggedIn: true,
                token: action.token,
                username: "Iframe User"
            })
        default:
            return state;
    }
}