import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios'
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import actions from '../../store/actions';

const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: '0 7px 8px -4px rgba(0,0,0,.2), 0 12px 17px 2px rgba(0,0,0,.14), 0 5px 22px 4px rgba(0,0,0,.12)!important'
  },
  login: {
    padding: '100px 0'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heading: {
      backgroundColor: '#054A91',
      color: 'white'
  }
});

function Login(props) {

    React.useEffect(() => {
        if(props.loggedIn) {
            props.history.push('/dashboard'+props.history.location.search)
        }
    }, [props]);// eslint-disable-line react-hooks/exhaustive-deps

    const api = axios.create({
        baseURL: 'https://mtc64dqix8.execute-api.ap-south-1.amazonaws.com/api/v1/',
    })

    let history = useHistory();

    const classes = useStyles();

    const [values, setValues] = React.useState({
    username: '',
    password: '',
    showPassword: false,
    });

    const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = event => {
    event.preventDefault();
    };

    function login() {
        api({
            url: 'login',
            method: 'POST',
            auth: {
                username: values.username,
                password: values.password
            }
        }).then(
            res => {
                console.log(res)
                console.log(props)
                props.login(res.data)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }

    return (
        <Grid container justify="center" alignItems="center" className={classes.login} >
            <Grid item xs={8} md={4}>
            <Card className={classes.root} >
            <CardHeader
                    title="Login"
                    className={classes.heading}
            >
            </CardHeader>
            <CardContent>
                <form noValidate autoComplete="off">
                <Grid container xs={12} spacing={1} style={{margin:'5px'}} alignItems="flex-end">
                    <Grid item xs={1}>
                        <PersonIcon />
                    </Grid>
                    <Grid item xs={11}>
                        <TextField fullWidth className={classes.formelement} id="input-with-icon-grid" label="Login" 
                        value={values.username}
                        onChange={handleChange('username')}/>
                    </Grid>
                </Grid>
                <Grid container xs={12} spacing={1} style={{margin:'5px'}} alignItems="flex-end">
                    <Grid item xs={1}>
                        <LockIcon />
                    </Grid>
                    <Grid item xs={11}>
                    <FormControl fullWidth className={clsx(classes.margin, classes.textField)}>
                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                        <Input
                            spacing={1}
                            id="standard-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                    </Grid>
                </Grid>
                </form>
            </CardContent>
            <CardActions>
                <Grid style={{width:'100%'}} container justify="flex-end">
                    <Button variant="contained" style={{backgroundColor:'#054A91', color: 'white', padding: '5px'}} size="small" onClick={login}>Login</Button>
                </Grid>
            </CardActions>
            </Card>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    loggedIn: state.loggedIn
})

const mapDispatchToProps = (dispatch) => ({
    login: (data) => dispatch(actions.LOGIN(data))
})

export default  connect(mapStateToProps, mapDispatchToProps)(Login)