import React from 'react';
import { Styles } from './styles';
import { Grid, CircularProgress, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FlipOver from 'react-flip-over';
import Chart from 'react-apexcharts';
import {
    overall_graph,
    region_wise_graph,
    transporter_vs_halts_graph,
    transporter_vs_trips_graph,
} from './graph-templates';

const useStyles = makeStyles((theme) => ({
    ...Styles,
}));

const getCommonName = (name = '') => {
    const nameMap = {
        'BOMBAY SOUTH': 'B.S.F.M',
        'KHUSHI RAM': 'KHUSHI RAM',
        'COMMERCIAL TRANSPORT': 'CT',
        'ANAYA LOGISTICS': 'ANAYA',
    };
    return nameMap[(name || '').toUpperCase()] || name.substring(0, 11);
};

const front = [
    getCommonName('BOMBAY SOUTH'),
    getCommonName('KHUSHI RAM'),
    getCommonName('YASH'),
    getCommonName('G TRANS'),
    getCommonName('COMMERCIAL TRANSPORT'),
    getCommonName('RUDRA'),
    getCommonName('EAST WEST'),
    getCommonName('PANWAR'),
    getCommonName('ANAYA LOGISTICS'),
    getCommonName('TRANSLINE'),
    getCommonName('PCC'),
];
const back = [
    getCommonName('BOMBAY SOUTH'),
    getCommonName('KHUSHI RAM'),
    getCommonName('YASH'),
    getCommonName('G TRANS'),
    getCommonName('COMMERCIAL TRANSPORT'),
    getCommonName('RUDRA'),
    getCommonName('EAST WEST'),
    getCommonName('PANWAR'),
    getCommonName('ANAYA LOGISTICS'),
    getCommonName('TRANSLINE'),
    getCommonName('PCC'),
];

const TRANSPORTERS_PER_GRAPH = 7;

function splitarray(input, spacing) {
    var output = [];

    for (var i = 0; i < input.length; i += spacing) {
        output[output.length] = input.slice(i, i + spacing);
    }

    return output;
}

function Graphs(props) {
    const classes = useStyles();

    const [graph1, setGraph1] = React.useState(overall_graph);
    const [graph2, setGraph2] = React.useState(region_wise_graph);
    const [graph3, setGraph3] = React.useState(transporter_vs_halts_graph);
    const [graph4, setGraph4] = React.useState(transporter_vs_trips_graph);

    const [graph3WithInterval, setgraph3WithInterval] = React.useState([]);
    const [graph4WithInterval, setgraph4WithInterval] = React.useState([]);

    const [currentGraph, setcurrentGraph] = React.useState(0);
    const [isGraphPause, setisGraphPause] = React.useState(false);

    const totalTransporters =
        props?.graphs?.front?.transporter_vs_halts_graph?.x?.length || 0;
    const noOfGraphs = Math.ceil(totalTransporters / TRANSPORTERS_PER_GRAPH);

    // const showTransporter = (name, flip) => {
    //     return (
    //         (flip === 'front' &&
    //             front.find((t) =>
    //                 t.toLowerCase().includes(name.toLowerCase())
    //             )) ||
    //         (flip === 'back' &&
    //             back.find((t) => t.toLowerCase().includes(name.toLowerCase())))
    //     );
    // };

    React.useEffect(() => {
        const id = setInterval(() => {
            if (!isGraphPause)
                setcurrentGraph((prevState) => (prevState + 1) % noOfGraphs);
        }, 5000);
        return () => clearInterval(id);
    });
    React.useEffect(() => {
        setGraph1({
            ...graph1,
            [props.flip]: {
                ...graph1[props.flip || 'front'],
                series: [
                    ...props.graphs[props.flip || 'front'].overall_graph.y,
                ],
                options: {
                    ...graph1[props.flip || 'front'].options,
                    labels: [
                        ...props.graphs[props.flip || 'front'].overall_graph.x,
                    ],
                },
            },
        });
        setGraph2({
            ...graph2,
            [props.flip]: {
                ...graph2[props.flip || 'front'],
                series: [
                    {
                        ...graph2[props.flip || 'front'].series[0],
                        data: [
                            ...props.graphs[props.flip || 'front']
                                .region_wise_graph.y,
                        ],
                    },
                ],
                options: {
                    ...graph2[props.flip || 'front'].options,
                    labels: [
                        ...props.graphs[props.flip || 'front'].region_wise_graph
                            .x,
                    ],
                },
            },
        });
        const graph3Data = {
            y: [],
            y1: [],
            y2: [],
            x: [],
        };
        props.graphs[
            props.graph3and4Flip || 'front'
        ].transporter_vs_halts_graph.x.forEach((data, index) => {
            const transporter = getCommonName(data);
            // if(!showTransporter(transporter, props.graph3and4Flip || "front")) return;
            graph3Data.x.push(transporter);
            graph3Data.y.push(
                props.graphs[props.graph3and4Flip || 'front']
                    .transporter_vs_halts_graph.y[index]
            );
            graph3Data.y1.push(
                props.graphs[props.graph3and4Flip || 'front']
                    .transporter_vs_halts_graph.y1[index]
            );
            graph3Data.y2.push(
                props.graphs[props.graph3and4Flip || 'front']
                    .transporter_vs_halts_graph.y2[index]
            );
        });

        setgraph3WithInterval((prevState) => {
            const graphsData = [];
            const splitedGraphData = {
                y: splitarray(graph3Data.y, TRANSPORTERS_PER_GRAPH),
                y1: splitarray(graph3Data.y1, TRANSPORTERS_PER_GRAPH),
                y2: splitarray(graph3Data.y2, TRANSPORTERS_PER_GRAPH),
                x: splitarray(graph3Data.x, TRANSPORTERS_PER_GRAPH),
            };
            for (let i = 1; i <= noOfGraphs; i++) {
                const graphData = {
                    ...(prevState.length === 0
                        ? transporter_vs_halts_graph
                        : prevState[i - 1]),
                    [props.graph3and4Flip]: {
                        ...transporter_vs_halts_graph[
                            props.graph3and4Flip || 'front'
                        ],
                        series: [
                            {
                                ...transporter_vs_halts_graph[
                                    props.graph3and4Flip || 'front'
                                ].series[0],
                                data: splitedGraphData.y[i - 1],
                            },
                            {
                                ...transporter_vs_halts_graph[
                                    props.graph3and4Flip || 'front'
                                ].series[1],
                                data: splitedGraphData.y1[i - 1],
                            },
                            {
                                ...transporter_vs_halts_graph[
                                    props.graph3and4Flip || 'front'
                                ].series[2],
                                data: splitedGraphData.y2[i - 1],
                            },
                        ],
                        options: {
                            ...transporter_vs_halts_graph[
                                props.graph3and4Flip || 'front'
                            ].options,
                            xaxis: {
                                ...transporter_vs_halts_graph[
                                    props.graph3and4Flip || 'front'
                                ].options.xaxis,
                                categories: splitedGraphData.x[i - 1],
                            },
                        },
                    },
                };

                graphsData.push(graphData);
            }

            return graphsData;
        });
        // setGraph3({
        //     ...graph3,
        //     [props.graph3and4Flip]: {
        //         ...graph3[props.graph3and4Flip || 'front'],
        //         series: [
        //             {
        //                 ...graph3[props.graph3and4Flip || 'front'].series[0],
        //                 data: [...graph3Data.y],
        //             },
        //             {
        //                 ...graph3[props.graph3and4Flip || 'front'].series[1],
        //                 data: [...graph3Data.y1],
        //             },
        //             {
        //                 ...graph3[props.graph3and4Flip || 'front'].series[2],
        //                 data: [...graph3Data.y2],
        //             },
        //         ],
        //         options: {
        //             ...graph3[props.graph3and4Flip || 'front'].options,
        //             xaxis: {
        //                 ...graph3[props.graph3and4Flip || 'front'].options
        //                     .xaxis,
        //                 categories: [...graph3Data.x],
        //             },
        //         },
        //     },
        // });
        const graph4Data = {
            y: [],
            y1: [],
            x: [],
        };
        props.graphs[
            props.graph3and4Flip || 'front'
        ].transporter_vs_trips_graph.x.forEach((data, index) => {
            // if (
            //     !showTransporter(
            //         getCommonName(data),
            //         props.graph3and4Flip || 'front'
            //     )
            // )
            //     return;
            graph4Data.x.push(getCommonName(data));
            graph4Data.y.push(
                props.graphs[props.graph3and4Flip || 'front']
                    .transporter_vs_trips_graph.y[index]
            );
            graph4Data.y1.push(
                props.graphs[props.graph3and4Flip || 'front']
                    .transporter_vs_trips_graph.y1[index]
            );
        });

        setgraph4WithInterval((prevState) => {
            const graphsData = [];
            const splitedGraphData = {
                y: splitarray(graph4Data.y, TRANSPORTERS_PER_GRAPH),
                y1: splitarray(graph4Data.y1, TRANSPORTERS_PER_GRAPH),
                x: splitarray(graph4Data.x, TRANSPORTERS_PER_GRAPH),
            };
            for (let i = 1; i <= noOfGraphs; i++) {
                const graphData = {
                    ...(prevState.length === 0
                        ? transporter_vs_trips_graph
                        : prevState[i - 1]),
                    [props.graph3and4Flip]: {
                        ...transporter_vs_trips_graph[
                            props.graph3and4Flip || 'front'
                        ],
                        series: [
                            {
                                ...transporter_vs_trips_graph[
                                    props.graph3and4Flip || 'front'
                                ].series[0],
                                data: splitedGraphData.y[i - 1],
                            },
                            {
                                ...transporter_vs_trips_graph[
                                    props.graph3and4Flip || 'front'
                                ].series[1],
                                data: splitedGraphData.y1[i - 1],
                            },
                        ],
                        options: {
                            ...transporter_vs_trips_graph[
                                props.graph3and4Flip || 'front'
                            ].options,
                            xaxis: {
                                ...transporter_vs_trips_graph[
                                    props.graph3and4Flip || 'front'
                                ].options.xaxis,
                                categories: splitedGraphData.x[i - 1],
                            },
                        },
                    },
                };

                graphsData.push(graphData);
            }

            return graphsData;
        });
        // setGraph4({
        //     ...graph4,
        //     [props.graph3and4Flip]: {
        //         ...graph4[props.graph3and4Flip || 'front'],
        //         series: [
        //             {
        //                 ...graph4[props.graph3and4Flip || 'front'].series[0],
        //                 data: [...graph4Data.y],
        //             },
        //             {
        //                 ...graph4[props.graph3and4Flip || 'front'].series[1],
        //                 data: [...graph4Data.y1],
        //             },
        //         ],
        //         options: {
        //             ...graph4[props.graph3and4Flip || 'front'].options,
        //             xaxis: {
        //                 ...graph4[props.graph3and4Flip || 'front'].options
        //                     .xaxis,
        //                 categories: [...graph4Data.x],
        //             },
        //         },
        //     },
        // });
    }, [props.graphs]);

    return (
        <div className={classes.container}>
            {graph1.front.series.length ? (
                <>
                    <div style={{ height: '10%', display: 'flex' }}>
                        <h3 style={{ fontSize: '1.2rem', margin: 'auto 0' }}>
                            Overall Performance
                        </h3>
                    </div>
                    <div style={{ height: '90%' }}>
                        <div style={{ height: '40%' }}>
                            <div
                                style={{
                                    width: '42%',
                                    float: 'left',
                                    height: '100%',
                                    border: '1.5px solid #E3E3E3',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <div style={{ display: 'flex', height: '10%' }}>
                                    <p
                                        style={{
                                            color: '#919191',
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem',
                                            margin: 'auto 15px',
                                        }}
                                    >
                                        Overall On-time and & Delay
                                    </p>
                                </div>
                                <div style={{ display: 'flex', height: '90%' }}>
                                    <Chart
                                        options={graph1.front.options}
                                        series={graph1.front.series}
                                        type='pie'
                                        height='100%'
                                        style={{ margin: 'auto' }}
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '55%',
                                    float: 'right',
                                    height: '100%',
                                    border: '1.5px solid #E3E3E3',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <div style={{ display: 'flex', height: '10%' }}>
                                    <p
                                        style={{
                                            color: '#919191',
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem',
                                            margin: 'auto 15px',
                                        }}
                                    >
                                        Region-wise Delay
                                    </p>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        height: '90%',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Chart
                                        options={graph2.front.options}
                                        series={graph2.front.series}
                                        type='bar'
                                        height='100%'
                                        style={{ margin: 'auto' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                height: '60%',
                                display: 'flex',
                                flexFlow: 'column',
                                justifyContent: 'space-evenly',
                            }}
                        >
                            {/* <div style={{height: "90%"}}> */}
                            {/* <div style={{height: "10%"}}>
                    <Grid container style={{height: "100%"}} justify="space-evenly">
                      <Grid item style={{height: "100%"}}>
                        <Grid container alignItems="flex-start" style={{height: "100%"}}>
                          <Chip style={{height:"100%", width:"35px", maxHeight: "18px", backgroundColor: "#A2DA9E", margin: 0}}/>  <p style={{fontSize: "0.7rem", margin: "0 5px"}}>On - Time Trips</p>
                        </Grid>
                      </Grid>
                      <Grid item style={{height: "100%"}}>
                        <Grid container alignItems="flex-start" style={{height: "100%"}}>
                          <Chip style={{height:"100%", width:"35px", maxHeight: "18px", backgroundColor: "#FFBCBC", margin: 0}}/>  <p style={{fontSize: "0.7rem", margin: "0 5px"}}>Delayed Trips</p>
                        </Grid>
                      </Grid>
                      <Grid item style={{height: "100%"}}>
                        <Grid container alignItems="flex-start" style={{height: "100%"}}>
                          <Chip style={{height:"100%", width:"35px", maxHeight: "18px", backgroundColor: "#6D8DB2", margin: 0}}/>  <p style={{fontSize: "0.7rem", margin: "0 5px"}}> {"<"} 6 halt</p>
                        </Grid>
                      </Grid>
                      <Grid item style={{height: "100%"}}>
                        <Grid container alignItems="flex-start" style={{height: "100%"}}>
                          <Chip style={{height:"100%", width:"35px", maxHeight: "18px", backgroundColor: "#3C6799", margin: 0}}/>  <p style={{fontSize: "0.7rem", margin: "0 5px"}}>{"<"} 12 halt</p>
                        </Grid>
                      </Grid>
                      <Grid item style={{height: "100%"}}>
                        <Grid container alignItems="flex-start" style={{height: "100%"}}>
                          <Chip style={{height:"100%", width:"35px", maxHeight: "18px", backgroundColor: "#9DB3CC", margin: 0}}/>  <p style={{fontSize: "0.7rem", margin: "0 5px"}}>{">"} 12 halt</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div> */}

                            <div
                                onMouseEnter={() => setisGraphPause(true)}
                                onMouseLeave={() => setisGraphPause(false)}
                                style={{
                                    height: '44%',
                                    border: '1.5px solid #E3E3E3',
                                    display: 'flex',
                                    flexFlow: 'column',
                                    justifyContent: 'space-evenly',
                                }}
                            >
                                <div style={{ display: 'flex', height: '10%' }}>
                                    <p
                                        style={{
                                            color: '#919191',
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem',
                                            margin: 'auto 15px',
                                        }}
                                    >
                                        Delay
                                    </p>
                                </div>
                                <Chart
                                    options={
                                        graph4WithInterval[currentGraph]?.front
                                            ?.options ||
                                        transporter_vs_trips_graph.front.options
                                    }
                                    series={
                                        graph4WithInterval[currentGraph]?.front
                                            ?.series ||
                                        transporter_vs_trips_graph.front.series
                                    }
                                    type='bar'
                                    height='80%'
                                />
                            </div>
                            <div
                                onMouseEnter={() => setisGraphPause(true)}
                                onMouseLeave={() => setisGraphPause(false)}
                                style={{
                                    height: '44%',
                                    border: '1.5px solid #E3E3E3',
                                    display: 'flex',
                                    flexFlow: 'column',
                                    justifyContent: 'space-evenly',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        height: '10%',
                                        alignItems: 'center',
                                    }}
                                >
                                    <p
                                        style={{
                                            color: '#919191',
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem',
                                            margin: 'auto 15px',
                                        }}
                                    >
                                        Halts
                                    </p>
                                </div>
                                <Chart
                                    options={
                                        graph3WithInterval[currentGraph]?.front
                                            ?.options ||
                                        transporter_vs_trips_graph.front.options
                                    }
                                    series={
                                        graph3WithInterval[currentGraph]?.front
                                            ?.series ||
                                        transporter_vs_trips_graph.front.series
                                    }
                                    type='bar'
                                    height='80%'
                                />
                            </div>
                            {/* </div> */}
                        </div>

                        <Grid
                            container
                            justify='space-between'
                            style={{ height: '100%', overflow: 'hidden' }}
                        >
                            {/* <Grid item style={{width: "40%"}}>
                  <Grid container direction="column" style={{height: "100%"}}>
                    <Grid item>
                      <p style={{color:"#919191", fontWeight: "bold"}}>Overall On-time and & Delay</p>
                    </Grid>
                    <Grid item style={{border: "1.5px solid #E3E3E3", flexGrow: 1, display: "flex"}}>
                      <Chart
                        options={graph1.front.options}
                        series={graph1.front.series}
                        type="pie"
                        height="200px"
                        style={{margin: "auto"}}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{width: "55%"}}>
                  <Grid container>
                    <Grid item xs={12}>
                      <p style={{color:"#919191", fontWeight: "bold"}}>Overall On-time and & Delay</p>
                    </Grid>
                    <Grid item xs={12} style={{border: "1.5px solid #E3E3E3"}}>
                      <Chart
                        options={graph2.front.options}
                        series={graph2.front.series}
                        type="bar"
                        height="200px"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <p style={{color:"#919191", fontWeight: "bold"}}>Delay & Halts</p>
                    </Grid>
                    <Grid item xs={12} style={{border: "1.5px solid #E3E3E3"}}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Chart
                              options={graph3.front.options}
                              series={graph3.front.series}
                              type="bar"
                              height="100%"
                            />
                        </Grid>
                        <Grid item xs={12}>
                          <Chart
                              options={graph4.front.options}
                              series={graph4.front.series}
                              type="bar"
                              height="100%"
                            />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}
                        </Grid>
                    </div>
                </>
            ) : (
                <div className={classes.loaderContainer}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
}

export default Graphs;
