import React from 'react';
import { Styles } from './styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FlipOver from 'react-flip-over';

const useStyles = makeStyles(theme => ({
    ...Styles
}));

function Overview(props) {

    const classes = useStyles();

    return (
        <Grid container className={classes.container} justify="center" alignItems="flex-start">
            {
                props.overview.front.length ? 
                <>
                <Grid item className={classes.heading} xs={12}>
                    <Typography variant="h4" className={classes.font}>Overview</Typography>
                </Grid>
                {
                    (props.overview.front || []).map((overview, index) => (
                        <Grid item xs={12} key={overview.label} className={classes.overview}>

                            <FlipOver flipped={props.flip !== "front"} duration={0.35} className={classes.flipCard}>
                            <Grid container key="front" justify="flex-end" alignItems="center" className={classes.flipCardFront}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.font}>{overview.label}</Typography>
                                </Grid>
                                <Grid item> 
                                <Typography variant="h4" className={classes[overview.label.split(" ").join("")] + " " + classes.font}>{overview.value}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container key="back" justify="flex-end" alignItems="center" className={classes.flipCardBack}>
                                <Grid item xs={12}>
                                <Typography variant="h4" className={classes.font}>{(props.overview.back[index] || {}).label}</Typography>
                                </Grid>
                                <Grid item> 
                                <Typography variant="h4" className={classes[(props.overview.back[index] || {label: ''}).label.split(" ").join("")] + " " + classes.font}>{(props.overview.back[index] || {}).value}</Typography>
                                </Grid>
                            </Grid>
                            </FlipOver>
                        </Grid>
                    ))
                }
                </> : <div className={classes.loaderContainer}><CircularProgress /></div>
            }
        </Grid>
    )
}

export default Overview;