import Container from './components/container/container';
import Navbar from './components/navbar/navbar';
import Login from './components/login/login';
import { connect } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import './App.css'
import React from 'react';
import actions from './store/actions';

function App(props) {

  const [iframe, setIframe] = React.useState(null);
  const history = useHistory();

  React.useEffect(() => {
    console.log(history.location)
    const token = (history.location.search.split("token=")[1] || "").split("&")[0];
    if (token) {
      setIframe(true);
      props.iframeLogin(token)
    }
  }, [])

  return (
    <div className="App">
      {
        iframe ? null :  <Navbar />
      }
      <Switch>
        <Route path="/dashboard" exact component={Container} />
        <Route path="/" component={Login} />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: state.loggedIn
})

const mapDispatchToProps = (dispatch) => ({
  iframeLogin: (token) => dispatch({type: 'IFRAME_LOGIN', token})
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
