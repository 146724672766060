export const Styles = {
    container: {
        height: '100%',
        // backgroundColor: '#EBEBEB',
        boxSizing: 'border-box',
        borderRadius: '2%',
        position: 'relative',
        // padding: '0 0 3% 0'
    },
    flipCard: {
        height: '100% !important',
        // height: '500px !important',
        width: '100% !important',
    },
    map: {
        height: '100%',
        width: '100%'
    },
    counter: {
        position: 'absolute',
        background: 'rgba(0, 99, 190, 0.4)',
        padding: '10px',
        color: 'white',
        fontWeight: '500',
        top: '5%',
        right: '5%'
    },
    info: {
        margin: '5px'
    },
    loaderContainer: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.4)',
        zIndex: '100',
    },
    transporterName: {
        backgroundColor: '#0063BE',
        borderRadius: '2%',
        color: 'white',
        padding: '2.6%',
        textAlign: 'center',
    },
    totalCount: {
        color: 'white',
        margin: '2%',
        backgroundColor: '#0063BE',
        padding: '2%'
    },
    ontimeCount: {
        color: 'white',
        margin: '2%',
        backgroundColor: '#50AD4C',
        padding: '2%'
    },
    delayCount: {
        color: 'white',
        margin: '2%',
        backgroundColor: '#EB5454',
        padding: '2%'
    },
}