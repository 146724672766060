import React from 'react';
import { Styles } from './styles';
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FlipOver from 'react-flip-over';
import mapboxgl from 'mapbox-gl'
// import '../MapView/MapView.css'

const useStyles = makeStyles(theme => ({
    ...Styles
}));

const image = {
    'Total Trips': 'https://assetsstatic.s3.ap-south-1.amazonaws.com/web_assets/counter_total.svg',
    'Ontime Trips': 'https://assetsstatic.s3.ap-south-1.amazonaws.com/web_assets/counter_online.svg',
    'Delayed Trips': 'https://assetsstatic.s3.ap-south-1.amazonaws.com/web_assets/counter_delayed.svg',
    'Pending Trips': 'https://assetsstatic.s3.ap-south-1.amazonaws.com/web_assets/counter_pending.svg',
    'Tracked Trips': 'https://assetsstatic.s3.ap-south-1.amazonaws.com/web_assets/counter_online.svg',
}

const tempCounter = [
    {
        label: 'Total Trips',
        value: '0',
    },
    {
        label: 'Ontime Trips',
        value: '0',
    },
    {
        label: 'Delayed Trips',
        value: '0',
    },
    {
        label: 'Pending Trips',
        value: '0',
    },
    {
        label: 'Tracked Trips',
        value: '0',
    },
]

function Map(props) {

    const classes = useStyles();

    const [state, setState] = React.useState({
        didMount: false,
        map: null,
        all_transporters: [],
        markers: [],
    })

    const [showingIndex, setShowingIndex] = React.useState(-1);

    const renderMap = (transporter) => {
        if(props.map[transporter]) {
            state.markers.forEach(marker => marker.remove())
            const markers = [];
            props.map[transporter].ontime.forEach(loc => {
                const el3 = document.createElement('div');
                el3.className = 'ontimemarker';
                let marker = new mapboxgl.Marker(el3)
                .setLngLat({
                    lat: loc[0],
                    lng: loc[1]
                })
                .addTo(state.map)
                markers.push(marker);
            })
            props.map[transporter].delay.forEach(loc => {
                const el3 = document.createElement('div');
                el3.className = 'delaymarker';
                let marker = new mapboxgl.Marker(el3)
                .setLngLat({
                    lat: loc[0],
                    lng: loc[1]
                })
                .addTo(state.map)
                markers.push(marker);
            })
            setState(state => ({
                ...state,
                markers 
            }))
        }
    }

    React.useEffect(state => {

         mapboxgl.accessToken = `pk.eyJ1IjoiYXl1c2hpbnR1Z2luZSIsImEiOiJjbHMxaWdramYwZzV3Mm1tazU4aTA5MzlkIn0.2EpIwfNReCEYLuSmPRT3Ag`;
        // mapboxgl.accessToken = `pk.eyJ1IjoiaGFyc2hpdHNocml2YXN0YXZhMTUiLCJhIjoiY2xvZWhiZXNoMGUzZjJuczFsaWNsY2N2bCJ9.DTHHyarMZ86IkN-EsTfejA`;

        // mapboxgl.accessToken = `pk.eyJ1IjoiaGFyc2hpdHNocml2YXN0YXZhMTUiLCJhIjoiY2xvZWhiZXNoMGUzZjJuczFsaWNsY2N2bCJ9.DTHHyarMZ86IkN-EsTfejA`;
        // mapboxgl.accessToken = `pk.eyJ1IjoibWF5YW5rbWl0dGFsaW50dWdpbmUiLCJhIjoiY2xxZDhsc25wMDlvNTJ2bTN3N3g3emZxbyJ9.8BmvDjhyLrfTtHbm7IiRMA`;

        // mapboxgl.accessToken = 'pk.eyJ1IjoibG92ZXByZWV0c2luZ2giLCJhIjoiY2xqZm53aDhkMDJ0NTNsdXBmbGZwd3NjNiJ9.tyfH6qc7p4v-UFzNc8Rkww';
        // 'pk.eyJ1IjoiaW50dWdpbmV0ZWNobm9sb2dpZXMiLCJhIjoiY2puMWdhazQ4MWkzNjNwcDcyeWl4ZGZoNCJ9.J7lsW734yAMVfkrqF4s0Kg';
        let map = new mapboxgl.Map({
            container: 'map', 
            style: 'mapbox://styles/mapbox/dark-v9',
            dragPan: false,
        });
        map.fitBounds([
            [68.14712, 31.63936],
            [98.34466, 13.20453]
        ]);
        setState(state => ({
            ...state,
            didMount: true,
            map
        }))
    }, [])

    React.useEffect(() => {
        const transporters = new Set([...state.all_transporters])
        Object.keys(props.map).forEach(transporter => {
            transporters.add(transporter);
        });
        setState(state => ({
            ...state,
            all_transporters: [...transporters]
        }))
    }, [props.map])

    React.useEffect(() => {
        renderMap(state.all_transporters[showingIndex])
        setTimeout(() => {
            const newIndex = state.all_transporters.length ? (showingIndex+1)%state.all_transporters.length : showingIndex+1;
            setShowingIndex(newIndex)
        }, 5000);
    }, [showingIndex])

    return (
        <Grid container direction="column" className={classes.container}>
            {
                state.all_transporters.length ? null : <div className={classes.loaderContainer}><CircularProgress /></div>
            }
            <Grid item style={{height:"10%", display: "flex"}}>
                <h3 style={{fontSize: "1.2rem", margin: "auto 0"}}>Transporter Name: {state.all_transporters[showingIndex] || "Loading Transporter"}</h3>
            </Grid>
            <Grid item style={{height: "86%", backgroundColor: "#F2F2F2", padding: "0 0 15px 0"}}>
                <Grid container style={{flexFlow: "row", height: "100%"}}>
                    <Grid item xs={12} md={4} ld={3}>
                        <Grid container direction="column" justify="space-evenly" style={{height: "100%"}}>
                            {
                                ((props.map[state.all_transporters[showingIndex]] || {}).counters || tempCounter).map((counter, index) => (
                                    <Grid item key={index} style={{margin: "0 15%"}}>
                                        <Grid container style={{border: '2px solid #0B417F', flexFlow:"row", color: "#0B417F"}} justify="space-around">
                                            <Grid item style={{margin: "5%"}}><img src={image[counter.label]} alt="" /></Grid>
                                            <Grid item>
                                                <Grid container justify="space-evenly" direction="column" style={{height: "100%"}}>
                                                    <Grid item style={{fontWeight: "500"}}>{counter.label}</Grid>
                                                    <Grid item style={{fontWeight: "700"}}>{counter.value}</Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                        <div id="map" className={classes.map}></div> 
                    </Grid>       
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Map;