export const Styles = {
    container: {
        margin: '2vh 0',
        height: '91vh',
        backgroundColor: '#EBEBEB',
        boxSizing: 'border-box',
        borderRadius: '2%',
    },
    heading: {
        backgroundColor: '#0063BE',
        borderRadius: '2%',
        color: 'white',
        padding: '5%',
        textAlign: 'center',
    },
    flipCardFront: {
        background: 'white',
        boxShadow: '0.2px 0.2px 5px 5px #cfcfcf',
        borderRadius: '3%',
        padding: '5%',
        margin: '5%',
        width: '90%',
        height: '90%',
        flexShrink: '0',
    },
    flipCardBack: {
        background: 'white',
        boxShadow: '0.2px 0.2px 5px 5px #cfcfcf',
        borderRadius: '3%',
        padding: '5%',
        margin: '5%',
        width: '90%',
        height: '90%',
        flexShrink: '0',
    },
    overview: {
        height: '17%',
    },
    flipCard: {
        height: '100% !important',
    },
    font: {
        fontFamily: "'Montserrat', sans-serif !important" ,
        fontWeight:  'bold',
    },
    OntimeTrips: {
        color: 'green',
    },
    Tripsabouttoreach: {
        color: 'green',
    },
    DelayedTrips: {
        color: 'red',
    },
    TripsatHalt: {
        color: 'red',
    },
    loaderContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.4)',
    }
}