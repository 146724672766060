import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Styles } from './styles';
import { connect } from 'react-redux';
import actions from '../../store/actions';

const useStyles = makeStyles(theme => ({
    ...Styles,
}))

function Navbar(props) {

    const classes = useStyles();

    return (
        <Grid container className={classes.container} alignItems="center">
            <Grid item style={{height:'100%'}}>
                <img src="https://assetsstatic.s3.ap-south-1.amazonaws.com/intutrack_logo.png" className={classes.logo}/>
            </Grid>
            <Grid item>
                <Typography variant="h6" className={classes.brand}>Intugine</Typography>
            </Grid>
            {
                props.loggedIn ? 
                <Grid item style={{marginLeft: 'auto'}}>
                    <Button onClick={props.logout} style={{color: 'white'}}>Logout</Button>
                </Grid> : null
            }
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    loggedIn: state.loggedIn
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(actions.LOGOUT())
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);