export const Styles = {
    container: {
        height: '5vh',
        boxSizing: 'border-box',
        backgroundColor: '#0063BE',
        color: 'white',
        display: 'flex',
        alignItems: 'center'
    },
    brand: {
        margin: '0 5%',
        fontFamily: "'Montserrat', sans-serif !important" 
    },
    logo: {
        margin: '0 10%',
        height: '100%',
    },
}