export const Styles = {
    container: {
        padding: '0 0 3% 0',
        height: '100%',
        boxSizing: 'border-box',
        borderRadius: '1%',
    },
    pieGraphLeft: {
        backgroundColor: '#EBEBEB',
        height: '30%',
        display: 'flex',
        alignItems: "center",
        marginRight: '2%',
        boxShadow: '0.2px 0.2px 5px 3px #cfcfcf',
        borderRadius: '1%',
        width: '48%'
    },
    pieGraphRight: {
        backgroundColor: '#EBEBEB',
        height: '30%',
        display: 'flex',
        alignItems: "center",
        marginLeft: '2%',
        boxShadow: '0.2px 0.2px 5px 3px #cfcfcf',
        borderRadius: '1%',
        width: '48%'
        // margin: '1.5% 0',
    },
    graph: {
        backgroundColor: '#EBEBEB',
        height: '30%',
        boxShadow: '0.2px 0.2px 5px 3px #cfcfcf',
        borderRadius: '1%',
        // margin: '1.5% 0',
    },
    flipCard: {
        height: '100% !important',
        width: '100% !important',
    },
    loaderContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.4)',
    }
}