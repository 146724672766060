const emptyOverAllGraph = {
    series: [100],
    options: {
        chart: {
            width: 380,
            type: 'pie',
            fontFamily: "'Montserrat', sans-serif !important" 
        },
        legend: {
            position: 'bottom',
        },
        colors:['#A2DA9E', '#FFBCBC'],
        // title: {
        //     text: "Ontime Delivery      (Last 7 days)",
        //     align: 'left',
        //     margin: 10,
        //     offsetX: 0,
        //     offsetY: 0,
        //     floating: false,
        //     style: {
        //         fontSize:  '15px',
        //         fontWeight:  'bold',
        //         fontFamily: "'Montserrat', sans-serif !important",
        //         color:  '#263238'
        //     },
        // },
        labels: ["Loading"],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                width: 200
                },
                legend: {
                position: 'bottom'
                }
            }
        }]
    },
}

const emptyRegionWiseGraph = {
    series: [
        {
            data: [0]
        },
    ],
    options: {
        legend: {
            show: false
        },
        chart: {
            width: 380,
            type: 'bar',
            fontFamily: "'Montserrat', sans-serif !important", 
            toolbar: {
              show: false
            }
        },
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        colors:['#A2DA9E'],
        // title: {
        //     text: "Region wise Ontime",
        //     align: 'center',
        //     margin: 10,
        //     offsetX: 0,
        //     offsetY: 0,
        //     floating: false,
        //     style: {
        //         fontSize:  '15px',
        //         fontWeight:  'bold',
        //         fontFamily:  "'Montserrat', sans-serif !important",
        //         color:  '#263238'
        //     },
        // },
        labels: ["Loading"],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                width: 200
                },
                legend: {
                position: 'bottom'
                }
            }
        }]
    },
}

const emptyTransporterVsHaltsGraph = {
    options: {
        // legend: {
        //     show: false
        // },
        chart: {
          id: "basic-bar",
          stacked: true,
          fontFamily: "'Montserrat', sans-serif !important",
          toolbar: {
            show: false
          }
        },
        colors:['#A2DA9E', 'rgba(255, 216, 115, 0.8)', '#FFBCBC'],
        xaxis: {
            labels: {
                rotate: -30
            },
            categories: ["Loading"]
        },
    //     title: {
    //       text: "Vehicles at halt",
    //       align: 'center',
    //       margin: 10,
    //       offsetX: 0,
    //       offsetY: 0,
    //       floating: false,
    //       style: {
    //         fontSize:  '15px',
    //         fontWeight:  'bold',
    //         fontFamily:  "'Montserrat', sans-serif !important",
    //         color:  '#263238'
    //     },
    //   },
    },
    series: [
        {
            name: "< 6 hrs",
            data: [0]
        },
        {
            name: "< 12 hrs",
            data: [0]
        },
        {
            name: "> 12 hrs",
            data: [0]
        },
    ]
}

const emptyTransporterVsTripsGraph = {
    options: {
        // legend: {
        //     show: false
        // },
        chart: {
          id: "basic-bar",
          stacked: true,
          fontFamily: "'Montserrat', sans-serif !important",
          toolbar: {
            show: false
          } 
        },
        colors:['#A2DA9E', '#FFBCBC'],
        xaxis: {
        labels: {
            rotate: -30
        },
          categories: ["Loading"]
        },
        // title: {
        //   text: "Transporter with total ontime and delayed trips",
        //   align: 'center',
        //   margin: 10,
        //   offsetX: 0,
        //   offsetY: 0,
        //   floating: false,
        //   style: {
        //     fontSize:  '15px',
        //     fontWeight:  'bold',
        //     fontFamily:  "'Montserrat', sans-serif !important",
        //     color:  '#263238'
        // },
    //   },
    },
    series: [
        {
            name: "Ontime",
            data: [0]
        },
        {
            name: "Delay",
            data: [0]
        }
    ]
}

export const overall_graph = {
    front: {
        ...emptyOverAllGraph,
    },
    back: {
        ...emptyOverAllGraph,
    }
}

export const region_wise_graph = {
    front: {
        ...emptyRegionWiseGraph,
    },
    back: {
        ...emptyRegionWiseGraph,
    }
}

export const transporter_vs_halts_graph = {
    front: {
        ...emptyTransporterVsHaltsGraph,
    },
    back: {
        ...emptyTransporterVsHaltsGraph,
    }
}
export const transporter_vs_trips_graph = {
    front: {
        ...emptyTransporterVsTripsGraph,
    },
    back: {
        ...emptyTransporterVsTripsGraph,
    }
}