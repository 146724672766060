import React from 'react';
import Overview from '../overview/overview';
import Map from '../map/map';
import Graphs from '../graphs/graphs';
import { Grid } from '@material-ui/core';
import {
    frontResponsePlaceHolder,
    backResponsePlaceHolder,
} from './plaeholder/sample-response';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Styles } from './styles';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    ...Styles,
}));

function Container(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        timeoutId: null,
        overview: {
            front: [],
            back: [],
        },
        graphs: {
            front: {
                overall_graph: {
                    x: [],
                    y: [],
                },
                region_wise_graph: {
                    x: [],
                    y: [],
                },
                transporter_vs_halts_graph: {
                    x: [],
                    y: [],
                    y1: [],
                    y2: [],
                },
                transporter_vs_trips_graph: {
                    x: [],
                    y: [],
                    y1: [],
                },
            },
            back: {
                overall_graph: {
                    x: [],
                    y: [],
                },
                region_wise_graph: {
                    x: [],
                    y: [],
                },
                transporter_vs_halts_graph: {
                    x: [],
                    y: [],
                    y1: [],
                    y2: [],
                },
                transporter_vs_trips_graph: {
                    x: [],
                    y: [],
                    y1: [],
                },
            },
        },
        map: {},
    });

    const [flip, setFlip] = React.useState('');
    const [didMount, setDidMount] = React.useState(false);
    const initData = (flip) => {
        const live = axios.get(
            `https://mtc64dqix8.execute-api.ap-south-1.amazonaws.com/api/v1/live?flip=${
                flip || 'front'
            }`,
            {
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
            }
        );
        const locs = axios.get(
            `https://mtc64dqix8.execute-api.ap-south-1.amazonaws.com/api/v1/live/locs`,
            {
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
            }
        );
        Promise.all([live, locs])
            .then(([res, mapRes]) => {
                const overview = res.data.overview;
                const graphs = { ...res.data };
                delete graphs.overview;
                setFlip(flip);
                if (props.loggedIn) {
                    console.log('adding timeout');
                    var id = setTimeout(() => {
                        initData(flip === 'front' ? 'back' : 'front');
                    }, 5000);
                } else {
                    console.log('over and out');
                }
                setState((state) => ({
                    ...state,
                    overview: {
                        ...state.overview,
                        [flip || 'front']: res.data.overview,
                    },
                    graphs: {
                        ...state.graphs,
                        [flip || 'front']: {
                            ...state.graphs[flip || 'front'],
                            ...graphs,
                        },
                    },
                    map: {
                        ...state.map,
                        ...mapRes.data,
                    },
                    timeoutId: id,
                }));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    React.useEffect(() => {
        initData('front');
        setDidMount(true);
    }, []);

    // React.useEffect(() => {
    //     if (!didMount) return;
    // }, [state.overview])

    React.useEffect(() => {
        if (!props.loggedIn) {
            clearTimeout(state.timeoutId);
            props.history.push('/');
        }
    }, [props]);

    return (
        <Grid
            container
            justify='space-evenly'
            style={{ height: '95vh', overflow: 'hidden' }}
            key={window.innerWidth + '' + window.innerHeight}
        >
            <Grid item xs={11} md={6} lg={6}>
                <Map map={state.map} flip='front' />{' '}
                {/* flip is front, means no flip*/}
            </Grid>
            <Grid item xs={11} md={5} lg={5}>
                <Graphs
                    graphs={state.graphs}
                    flip='front'
                    graph3and4Flip={flip}
                />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    loggedIn: state.loggedIn,
    token: state.token,
});

export default connect(mapStateToProps)(Container);
